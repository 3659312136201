import $ from "jquery"

$(function() {

    const $mobilemenu = $("#mobilemenu");
    const $hamburger = $("#hamburger-mobilemenu");
    const $hamtext = $("#ham-txt");
    const $logo = $(".logo");
    const $body = $("body");

    $hamburger.on("click", function() {
        toggleMobileMenu();
    });

    function toggleMobileMenu() {
        const mobileMenuVisible = $mobilemenu.hasClass("visible");
        if(mobileMenuVisible) {
            hideMobileMenu();
        } else {
            showMobileMenu();
        }
    }

    function showMobileMenu() {
        $mobilemenu.addClass("visible");
        $hamburger.addClass("is-active");
        $hamtext.text("schliessen");
        $logo.addClass("hide");
        $body.addClass("mobilemenuvisible");
    }

    function hideMobileMenu() {
        $mobilemenu.removeClass("visible");
        $hamburger.removeClass("is-active");
        $hamtext.text("Menü");
        $logo.removeClass("hide");
        $body.removeClass("mobilemenuvisible");
    }

    function enhanceHtml() {
        $mobilemenu.find("li.hassubmenu > a").append('<svg class="icon icon-arrowright"><use xlink:href="#icon-arrowright"></use></svg>');
        $mobilemenu.find(".backlink").prepend('<svg class="icon icon-arrowleft"><use xlink:href="#icon-arrowleft"></use></svg>');
    }
    enhanceHtml();
});

$(function() {
/*    $('.parentlink').click(function(){
        $('.submenu').removeClass('open');

        var submenu = $(this).next('.submenu');
        submenu.addClass('open');
        event.preventDefault();
    });*/


    $('.parentlink').click(function(e){
        e.preventDefault();
        var submenu = $(this).parent().children('.submenu');
        $('.submenu').not(submenu).removeClass('open').addClass('closed');
        submenu.addClass('open').removeClass('closed');
        $(this).attr('aria-expanded', function(_, attr){
            return attr == 'true' ? 'false' : 'true';
        });
    });

});

