import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

function scrollToAnchor(anchor) {
    const $scrolltarget = $("section[data-scrolltarget="+anchor+"]");
    const margintop = parseInt($scrolltarget.css("margin-top"));
    $(window).scrollTo($scrolltarget, 1000, {
        offset: -margintop
    });
}

$(function() {
    const $sections = $("section[data-anchor]");
    if($sections.length == 0) {
        return;
    }
    
    const links = [];
    let anchoridx = 1;
    $sections.each(function() {
        const $section = $(this);
        const anchortitle = $section.data("anchor");
        const anchorid = "inpagenav"+anchoridx;

        $section.attr("data-scrolltarget", anchorid);

        const $a = $("<a></a>");
        $a.on("click", function() {
            scrollToAnchor(anchorid);
        });
        $a.text(anchortitle);
        links.push($a);

        anchoridx++;
    });

    const $inpagenav = $(".inpage-nav");
    for(const $link of links) {
        $inpagenav.append($link);
    }
});