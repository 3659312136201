
import { ready } from "./utils.js";

//50% => offset:0.5: https://stackoverflow.com/questions/64271754/javascript-animate-key-frames-by-percentage
const animations = {
    "fadeInLeft": {
        "keyframes": [
            { opacity: "0", transform: "translateX(-1px)" },
            { },
        ],
        "options": {
            duration: 1000,
            easing: "ease"
        }
    },
    "fadeInRight": {
        "keyframes": [
            { opacity: "0", transform: "translateX(0)" },
            { },
        ],
        "options": {
            duration: 1000,
            easing: "ease"
        }
    }
};

ready(initReveal);

function initReveal() {
    createObserver();
    
    document.body.classList.add("reveal-initialized");
}

function createObserver() {
    const options = {
        root: null, //null=window
        rootMargin: "0% 0% -5% 0%", //unten -5%
        threshold: 0.25 //triggert, wenn Element zu 25% sichtbar (innerhalb rootMargin)
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    const targetElements = document.querySelectorAll(".reveal");
    targetElements.forEach((el) => {
        el.classList.add("reveal-hidden");
        observer.observe(el);
    });
}

function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
        if(entry.isIntersecting) {
            itemsToReveal.push(entry.target);
            observer.unobserve(entry.target);

            ensurePollIntervalRunning(true);
        }
    });
};

let revealPollInterval = null;
function ensurePollIntervalRunning(runImmediately) {
    if(!isPollIntervalRunning()) {
        startPollInterval();
        if(runImmediately) {
            revealNext();
        }
    }
}
function startPollInterval() {
    revealPollInterval = setInterval(revealNext, 100);
}
function shutdownPollInterval() {
    clearInterval(revealPollInterval);
    revealPollInterval = null;
}
function isPollIntervalRunning() {
    return revealPollInterval != null;
}

const itemsToReveal = [];
function revealNext() {
    const el = itemsToReveal.shift();
    if(el) {
        const revealAnimation = el.dataset.revealAnimation;
        el.classList.remove("reveal-hidden");

        const animation = animations[revealAnimation];
        if(animation) {
            el.animate(animation.keyframes, animation.options);
        }

        //const animationClass = "reveal-"+revealAnimation;
        //el.classList.add(animationClass);

        /*el.addEventListener("animationend", function() {
            //CSS transitions sind nicht animiert, wenn animation-name auf Element aktiviert
            //Beispiel: https://dev.kilian-kuechen.pixmill.ch/inspiration/
            el.classList.remove(animationClass);
        }, {
            "once": true
        })*/
    } else {
        shutdownPollInterval();
    }
}