import $ from "jquery"

//Menu aus- und einblenden, Klassen hinzufÃ¼gen/entfernen je nach up-down-scroll
var lastScrollTop = 0;
$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  if (scroll > lastScrollTop){ //scroll down
    if (scroll > 20) { //scroll down more than  50 px
      $(".home-logo").addClass("hide");
    }
    if (scroll >= 40) { //scroll down more than  50 px
      $("header").addClass("moveup");
    }

  } else { //scroll up
    if (scroll > 20) { //scroll down more than  50 px
      $(".home-logo").removeClass("hide");
    }
    $("header").removeClass("moveup").addClass("showbg");
    if (scroll < 40) { //scroll down more than  50 px
      $("header").removeClass("showbg");
    }
  }
  lastScrollTop = scroll;
});
